import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import { loginout } from '@/api/login';

export default {
	data() {
		return {
			showLoginPop: false,
      showDialog: false
		};
	},

	computed: {
    ...mapGetters(['userName', 'photo', 'isLogin', 'loginType']),

    loginTypeFormat() {
      const names = {
        0: 'Apple',
        1: 'Google',
        2: 'Email'
      };

      return names[this.loginType];
    }
  },

	methods: {
		back() {
			this.$router.back();
		},

		goLogin() {
      this.$router.push({
        path: `/login?redirect_url=${window.location.href}`
      });
    },

    showAppstore() {
      this.showDialog = false;
      window.location.href = 'itms-apps://itunes.apple.com/app/id6738687775';
    },

    async signout() {
    	this.showLoginPop = false;

      const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });
      const userToken = localStorage.getItem('user_token') || '';
      const res = await loginout({
        token: userToken
      });

      localStorage.setItem('user_token', '');
      this.$message({
        message: 'The sign out is successful',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
      this.$store.dispatch('updateUserInfo');
      loading.close();
      this.goLogin();
    },
	}
}