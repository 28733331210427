const mutations = {
	['UPDATE_USER_INFO'] (state, userInfo) {
		state.isLogin = userInfo.isLogin || false;
		state.userName = userInfo.userName || '';
		state.type = userInfo.type || 0;
		state.photo = userInfo.photo || '';
		state.loginType = userInfo.loginType;
	},

	['UPDATE_SETTING'] (state, payload) {
		state.setting = payload;
	},

	['UPDATE_PLAN'] (state, payload) {
		state.vipLevel = payload.type;
		state.vipExpireDate = payload.vipExpireDate;
	},

	['UPDATE_LEMON_BEAN_COUNT'] (state, payload) {
		const { lemonBeanCount } = payload;
		
		state.lemonBeanCount = lemonBeanCount;
	}
};

export default mutations;