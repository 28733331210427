import { sleep } from '@/utils/util';
import { getUserInfo } from '@/api/user';
import { logout } from '@/api/login';

const actions = {
	async updateUserInfo({ commit, state, rootState }, payload) {
		const userToken = localStorage.getItem('user_token') || '';

		if (!userToken) {
			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				type: 0,
				photo: '',
				loginType: null
			});
			return;
		}

		try {
			const res = await getUserInfo({
				token: userToken
			});

			if (res.statusCode === 200) {
				commit('UPDATE_USER_INFO', {
					isLogin: true,
					userName: res.data.nickName,
					photo: res.data.avatar,
					loginType: res.data.registerChannel
				});
				return;
			}

			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				type: 0,
				photo: '',
				loginType: null
			});
		}
		catch(err) {
			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				type: 0,
				photo: '',
				loginType: null
			});
		}
	},

	updateSetting({ commit, state, rootState }, payload) {
		commit('UPDATE_SETTING', {
			...payload
		});
	},

	updatePlan({ commit, state, rootState }, payload) {
		commit('UPDATE_PLAN', {
			...payload,
		});
	},

	async loginOut({ commit, state, rootState }) {
		await logout();
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			type: 0
		});
	},

	async clearLoginStatus({ commit, state, rootState }) {
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			type: 0
		});
	}
}

export default actions;
