import { Loading } from 'element-ui';
import { initializeApp } from "firebase/app";
import { loginWithGoogle, loginWithApple } from '@/api/login';
import { getUserInfo } from '@/api/user';
import { queryUrl } from '@/utils/util';
import { getAuth, signInWithRedirect, getRedirectResult, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import firebase from '@/firebase';

export default {
	data() {
		return {

		}
	},

	methods: {
		back() {
			this.$router.back();
		},

		async loginGoogle() {
			const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });
			const { redirect_url } = queryUrl(window.location.href);
			const provider = new GoogleAuthProvider();
			const authResult = await signInWithPopup(firebase.auth, provider);
			const user = authResult.user;
			const inviteCode = localStorage.getItem('invite_code') || '';
      const res = await loginWithGoogle({
      	uid: user.uid,
      	token: user.accessToken,
      	inviteCode
      });

      if (res.statusCode !== 200) {
      	this.$message({
		      message: 'The login is failed',
		      type: 'error',
		      showClose: true,
		      customClass: 'custom-message',
		    });
		    loading.close();
		    return;
      }

      localStorage.setItem('user_token', res.data);

	    if (redirect_url) {
	    	this.$message({
		      message: 'The login is successful',
		      type: 'success',
		      showClose: true,
		      customClass: 'custom-message',
		    });
        window.location.href = decodeURIComponent(redirect_url);
        loading.close();
        return;
      }

      await this.$store.dispatch('updateUserInfo');
      this.$message({
	      message: 'The login is successful',
	      type: 'success',
	      showClose: true,
	      customClass: 'custom-message',
	    });
      this.$router.push({
        path: '/'
      });
      loading.close();
		},

		async loginApple() {
			const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });
			const { redirect_url } = queryUrl(window.location.href);
			const provider = new OAuthProvider('apple.com');
      const authResult = await signInWithPopup(firebase.auth, provider);
			const user = authResult.user;
			const inviteCode = localStorage.getItem('invite_code') || '';
      const res = await loginWithApple({
      	uid: user.uid,
      	token: user.accessToken,
      	inviteCode
      });

      if (res.statusCode !== 200) {
      	this.$message({
		      message: 'The login is failed',
		      type: 'error',
		      showClose: true,
		      customClass: 'custom-message',
		    });
		    loading.close();
		    return;
      }

      localStorage.setItem('user_token', res.data);
      this.$message({
	      message: 'The login is successful',
	      type: 'success',
	      showClose: true,
	      customClass: 'custom-message',
	    });

	    if (redirect_url) {
        window.location.href = decodeURIComponent(redirect_url);
        loading.close();
        return;
      }

      await this.$store.dispatch('updateUserInfo');
      loading.close();
      this.$router.push({
        path: '/'
      });
		}
	}
}