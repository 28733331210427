import { network } from './network';

export async function loginWithGoogle(opts) {
	const res = await network({
		url: '/ai/api/web/v1/user/login/google',
		method: 'post',
		params: {
			...opts
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function loginWithApple(opts) {
	const res = await network({
		url: '/ai/api/web/v1/user/login/apple',
		method: 'post',
		params: {
			...opts
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function loginout(opts) {
	const res = await network({
		url: '/ai/api/web/v1/user/logout',
		method: 'get',
		params: {},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}