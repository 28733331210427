import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
import { loadingIcon } from '@/svg';
import { loginout } from '@/api/login';
import { queryRoleDetail, queryRoleCards } from '@/api/role';

export default {
	data() {
		return {
			pageLoading: false,
			cardsLoading: false,
			loadingIcon,
			showLoginPop: false,
			roleInfo: {},
			cardList: []
		}
	},

	computed: {
    ...mapGetters(['userName', 'photo', 'isLogin']),
  },

	activated() {
		this.init();
	},

	methods: {
		back() {
			this.$router.back();
		},

		goLogin() {
      this.$router.push({
        path: `/login?redirect_url=${window.location.href}`
      });
    },

    goDownload() {
    	this.$router.push({
        path: `/download`
      });
    },

    async signout() {
      const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });

      const userToken = localStorage.getItem('user_token') || '';
      const res = await loginout({
        token: userToken
      });

      localStorage.setItem('user_token', '');
      this.$message({
        message: 'The sign out is successful',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
      this.$store.dispatch('updateUserInfo');
      loading.close();
      this.showLoginPop = false;
    },

		async init() {
			this.cardsLoading = false;
			this.pageLoading = true;
			await this.getRoleInfo();
			this.pageLoading = false;
			await this.getCardList();
		},

		async getRoleInfo() {
			const res = await queryRoleDetail({
				roleId: this.$route.params.roleId
			});

			this.roleInfo = res.data;
		},

		async getCardList() {
			this.cardsLoading = true;
			const res = await queryRoleCards({
				roleId: this.$route.params.roleId
			});

			this.cardList = res.data;
			this.cardsLoading = false;
		}
	}
}