import axios from 'axios';
import { eventBus } from '@/utils/event';

const showLog = localStorage.show_log === '1';

const instance = axios.create({
  timeout: 30000,
  headers: {}
});

export function network(opts) {
	const { method, params = {}, url, headers = {} } = opts;
	
	if (method === 'post') {
		return new Promise((resolve, reject) => {
			instance.post(url, {
			  ...params
			}).then((res) => {
				const body = res.data;

				resolve(body);
			}).catch((err) => {
				if (err.response && err.response.status === 401) {
					reject({
						code: 1000,
						message: '登录过期，请重新登录'
					});
					eventBus.$emit('relogin');
					return;
				}

				if (err.response && err.response.status === 403) {
					reject({
						code: 1001,
						message: '无权限'
					});
					return;
				}

				if (err.response && err.response.status === 450) {
					reject({
						code: 1006,
						message: '检测到新版本，请刷新页面更新应用'
					});
					eventBus.$emit('updateApp');
					return;
				}

				reject({
					code: 1002,
					message: 'Network error'
				});
			});
		});
	}

	if (method === 'get') {
		return new Promise((resolve, reject) => {
			instance.get(url, {
			  params,
			  headers
			}).then((res) => {
				const body = res.data;

				resolve(body);
			}).catch((err) => {
				if (err.response && err.response.status === 401) {
					reject({
						code: 1000,
						message: '登录过期，请重新登录'
					});
					eventBus.$emit('relogin');
					return;
				}

				if (err.response && err.response.status === 403) {
					reject({
						code: 1001,
						message: '无权限'
					});
					return;
				}

				if (err.response && err.response.status === 450) {
					reject({
						code: 1006,
						message: '检测到新版本，请刷新页面更新应用'
					});
					eventBus.$emit('updateApp');
					return;
				}

				reject({
					code: 1002,
					message: '网络错误'
				});
			});
		});
	}
}