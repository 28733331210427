export default {
	data() {
		return {}
	},

	mounted() {
		const isSafe = this.$route.query.is_safe || '0';

		if (isSafe === '1') {
			window.location.href = 'https://discord.gg/5sZesfcj';
		}
		else {
			window.location.href = 'https://discord.gg/3gTbHfCMMK';
		}
	}
};