import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/pages/Home/Home.vue';
import RoleDetail from '@/pages/RoleDetail/RoleDetail.vue';
import Login from '@/pages/Login/Login.vue';
import Download from '@/pages/Download/Download.vue';
import Contact from '@/pages/Contact/Contact.vue';
import NotFound from '@/pages/404/404.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
  },
  {
    path: '/role_detail/:roleId',
    name: 'role_detail',
    component: RoleDetail,
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  },
];

const router = new VueRouter({
  base: '/web',
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  const isLogin = store.getters.isLogin;

  // 如果直接访问的是登录页，并且没有登录，则渲染登录页面
  if (/\/login/.test(to.fullPath) && !isLogin) {
    next();
    return;
  }

  // 如果直接访问的是登录页，已登录，则重定向到聊天页面
  if (/\/login/.test(to.fullPath) && isLogin) {
    next('/');
    return;
  }

  if (!isLogin && /\/download/.test(to.fullPath)) {
    next(`/login?redirect_url=${window.location.href}`);
    return;
  }

  next();
})

export default router;


