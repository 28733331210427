import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI, { Loading } from 'element-ui';
import { sleep } from '@/utils/util';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';

const unfiltered = localStorage.getItem('unfiltered') || '';

if (!unfiltered) {
  localStorage.setItem('unfiltered', '0');
}

Vue.use(ElementUI, {locale});
Vue.config.productionTip = false;

async function getUserInfo() {
  const loading = Loading.service({
    fullscreen: true,
    customClass: 'app-loading',
    background: '#1c1d21'
  });

  await store.dispatch('updateUserInfo');
  loading.close();
}

Promise.all([
  getUserInfo()
]).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});
