const getters = {
	isLogin(state, getters) {
		return state.isLogin;
	},

	loginType(state, getters) {
		return state.loginType;
	},

	userName(state) {
		return state.userName;
	},

	photo(state) {
		return state.photo;
	}
};

export default getters