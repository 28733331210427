import { initializeApp } from 'firebase/app';
import { getAuth, signInWithRedirect, getRedirectResult, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAObgiHG1ZZo84l1mpcJ7bPyQijIq7Hy-c",
  authDomain: "talko-4ef53.firebaseapp.com",
  projectId: "talko-4ef53",
  storageBucket: "talko-4ef53.firebasestorage.app",
  messagingSenderId: "78721733226",
  appId: "1:78721733226:web:c6346e31103997c4613013"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default {
  app,
  auth
};