import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import RoleCard from '@/components/RoleCard/RoleCard.vue';
import { loadingIcon } from '@/svg';
import { loginout } from '@/api/login';
import { queryTopRoles, queryTabRoles } from '@/api/role';

export default {
  name: 'Home',

  data() {
    return {
      pageLoading: false,
      unfiltered: false,
      loadingIcon,
      topList: [],
      tabList: [],
      roleList: [],
      activeTabId: '',
      page: 0,
      roleListLoading: false,
      nomoreData: false,
      showLoginPop: false,
      showDialog: false,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 10,
        initialSlide: 1,
        centeredSlides: true,
        loop: true,
        effect : 'coverflow',
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 200,
          modifier: 2,
          slideShadows : true
        }
      }
    }
  },

  computed: {
    ...mapGetters(['userName', 'photo', 'isLogin']),
  },

  created() {
    const inviteCode = this.$route.query.invite_code || '';
    const unfiltered = localStorage.getItem('unfiltered') || '';

    localStorage.setItem('invite_code', inviteCode);
    this.unfiltered = unfiltered === '1';
  },

  methods:{
    goLogin() {
      this.$router.push({
        path: `/login?redirect_url=${window.location.href}`
      });
    },

    unfilteredChange(value) {
      if (value) {
        this.showDialog = true;
      }
    },

    over18() {
      this.showDialog = false;
      this.unfiltered = true;
      this.updateUnfiltered();
    },

    cancel18() {
      this.showDialog = false;
      this.unfiltered = false;
      this.updateUnfiltered();
    },

    updateUnfiltered() {
      localStorage.setItem('unfiltered', this.unfiltered ? '1' : '0');
    },

    async signout() {
      const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });

      const userToken = localStorage.getItem('user_token') || '';
      const res = await loginout({
        token: userToken
      });

      localStorage.setItem('user_token', '');
      this.$message({
        message: 'The sign out is successful',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
      this.$store.dispatch('updateUserInfo');
      loading.close();
      this.showLoginPop = false;
    },

    async init() {
      this.queryInitialData();
    },

    async queryInitialData() {
      this.pageLoading = true;
      const res = await queryTopRoles();

      this.topList = this.makeTopRenderList(res.data.recommends);
      this.pageLoading = false;
      this.tabList = this.makeTabRenderList(res.data.categories);
      this.activeTabId = this.tabList[0].id;
      this.roleListLoading = true;
      await this.getRoleList({
        cid: this.activeTabId,
        page: this.page
      });
      this.roleListLoading = false;
    },

    onClickCard(opts) {
      this.$router.push({
        path: `/role_detail/${opts.roleId}`
      });
    },

    async switchTab(cid) {
      this.page = 0;
      this.nomoreData = false;
      this.activeTabId = cid;
      this.roleListLoading = true;
      this.roleList = [];
      await this.getRoleList({
        cid: this.activeTabId,
        page: this.page
      });
      this.roleListLoading = false;
    },

    async getRoleList(opts) {
      const { cid, page } = opts;
      const res = await queryTabRoles({
        cid,
        page
      });
      const list = this.makeRoleRenderList(res.data);

      if (!list.length) {
        this.nomoreData = true;
        return;
      }
      else {
        this.nomoreData = false;
      }

      this.roleList = this.roleList.concat(list);
    },

    async onScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      
      if (this.roleListLoading || this.nomoreData || this.pageLoading) {
        return;
      }

      if(scrollTop + clientHeight >= scrollHeight - 200) {
        this.page++;
        this.roleListLoading = true;
        await this.getRoleList({
          cid: this.activeTabId,
          page: this.page
        });
        this.roleListLoading = false;
      }
    },

    makeRoleRenderList(srcList) {
      const result = [];

      srcList.forEach((item) => {
        result.push({
          avatar: item.avatar,
          cover: item.cover,
          description: item.description,
          id: item.id,
          nickName: item.nickName,
          tags: item.tags
        });
      });

      return result;
    },

    makeTopRenderList(srcList) {
      const result = [];

      srcList.forEach((item) => {
        result.push({
          cover: item.cover,
          id: item.id,
          nickName: item.nickName,
          tags: item.tags
        });
      });

      return result;
    },

    makeTabRenderList(srcList) {
      const result = [];

      srcList.forEach((item) => {
        result.push({
          name: item.name,
          id: item.id,
        });
      });

      return result;
    },

    onSlideChange(){
      
    }
  },

  mounted() {
    this.init();
  },

  components: {
     Swiper,
     SwiperSlide,
     RoleCard
  }
}
