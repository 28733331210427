export function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function queryUrl(url) {
  let paramStr = url.split('?')[1];
  let result = {};

  if (!paramStr) {
      return result;
  }

  let paramList = paramStr.split('&');

  paramList.forEach((param) => {
      let key = param.split('=')[0];
      let value = param.split('=')[1];

      result[key] = value;
  });

  return result;
}